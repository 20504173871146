<template>
    <div class="station-8 partBox">
        <MyTitle class="flexs" title="电池数据" />
        <div class="flex flexs top-info justify-content-between mt-30">
            <div v-for="(item, i) in list"
                :key="'list' + i"
                class="item flex1">
                <!-- <img class="img" :src="item.icon" /> -->
                <div class="number" :style="{'color': item.color }">{{ item.value }}</div>
                <div class="label">{{ item.label }}</div>
            </div>
        </div>
        <div class="box-content flex1">
            <el-carousel
                class="my-carousel" height="100%" direction="vertical" indicator-position="none">
                <el-carousel-item
                    v-for="(battery, i) in batteryHolder"
                    :key="'batteryHolder' + i">
                    <div class="flex flex-wrap">
                        <div
                            v-for="(item, i) in battery"
                            :key="'battery' + i"
                            class="width-50 item mt-30">
                            <div class="flex item-top align-items">
                                <!-- 1-待机；2-充电；3-充电完成；0和4-故障；5- 离线 -->
                                <div class="batteryIcon"></div>
                                <span>SOC</span>
                                <div class="batteryNum">
                                    {{ parseInt(item.currentSoc) }}<span class="text-10">%</span>
                                </div>
                            </div>
                            <!-- 电池 -->
                            <div
                                class="battery-color mt-20"
                                :class="{'battery-100': item.status == 3 || item.currentSoc == 100,
                                    'battery-ing': item.status == 2,
                                    'battery-bad': [1, '1', 4, '4', 5, '5'].includes(item.status)}">
                            </div>

                            <div class="item-bottom mt-10">
                                NO.{{ item.positionNo }}
                                <!-- <span v-show="!item.temperature">(温度告警)</span> -->
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>

<script>
import MyTitle from '@/views/components/MyTitle'
import { dataInterface } from "@/api/dataInterfaceApi"
export default {
    components: {
        MyTitle,
    },
    data() {
        let { stationId } = this.$route.query || {}
        return {
            stationId,
            list: [
                {
                    label: '可用电池',
                    value: '',
                    color: '#24C953'
                    // icon: require('@/assets/images/c÷honghuan/icon-22.png'),
                },
                {
                    label: '充电中电池',
                    value: '',
                    color: '#24C953'
                    // icon: require('@/assets/im÷ages/chonghuan/icon-23.png'),
                },
                {
                    label: '其他',
                    value: '',
                    color: '#C4EDD1'
                    // icon: require('@/assets/images/÷chonghuan/icon-23.png'),
                }
            ],
            batteryHolder: []
        }
    },
    mounted() {
        // 获取数据
        this.getData()
    },
	methods: {
        getData() {
            // 电池仓信息
            this.selectStationBatteryHolder()
        },
        // 电池仓信息
        selectStationBatteryHolder(){
            let _this = this
            let params = {
                'enCode':'chongdiancang-TiDB',
                '@station_id': this.stationId,
            }
            dataInterface.dataFaceApi(params).then((res) => {
                if(!res || !res.length) return;
                let num1 = 0
                let num2 = 0
                let arry = []
                _this.batteryHolder = []
                res.forEach((item, i) => {
                    if(item.status == 3) {
                        num1++
                    }
                    if(item.status == 2) {
                        num2++
                    }
                    arry.push({
                        ...item,
                        temperature: item.temperature > 60 ? false : true
                    })
                    if(arry.length === 10 || (i + 1 === res.length)) {
                        _this.batteryHolder.push(arry)
                        arry = []
                    }
                });
                // _this.batteryHolder = res.map(item =>{
                //     if(item.status == 3) {
                //         num1++
                //     }
                //     if(item.status == 2) {
                //         num2++
                //     }
                //     return {
                //         ...item,
                //         temperature: item.temperature > 60 ? false : true
                //     }
                // })
                this.list[0].value = num1
                this.list[1].value = num2
                this.list[2].value = res.length - num1 - num2
            })
            .catch((err) => {
                console.log(err);
            });
        },
    }
}
</script>

<style lang="scss" scoped>
$BatteryWidth: 200px;
.station-8 {
    height: 100%;
    .top-info {
        background: url('~@/assets/images/chonghuan/icon-25.png') bottom no-repeat;
        background-size: 100%;
        padding-left: 70px;
        position: relative;
        &::before {
            position: absolute;
            width: 100px;
            height: 100px;
            top: 0;
            left: 0;
            background: url('~@/assets/images/chonghuan/icon-25-1.png') bottom no-repeat;
            background-size: 100%;
            content: '';
        }
        .item {
            position: relative;
            // width: 283px;
            height: 115px;
            text-align: center;
            // padding-left: 40px;
            // .img {
            //     position: absolute;
            //     width: 283px;
            //     height: 78px;
            //     bottom: 0;
            //     left: 0;
            // }
            .number {
                position: relative;
                font-size: $FontSize64;
                @include ffamily-HT;
                height: 60px;
                line-height: 60px;
            }
            .label {
                line-height: 30px;
                position: relative;
                font-size: $FontSize28;
                white-space: nowrap;
            }
        }
    }
    .box-content {
        // overflow-y: scroll;
        .my-carousel {
            height: 100%;
        }
        .item {
            .item-top {
                height: 36px;
                font-size: $FontSize32;
                color: #A5A5A5;
                .batteryIcon {
                    width: 40px;
                    height: 40px;
                    background: url('~@/assets/images/chonghuan/icon-28.png') no-repeat;
                    background-size: 100%;
                    margin-right: 15px;
                }
                .batteryNum {
                    color: #fff;
                    margin-left: 10px;
                }
            }

            .battery-color {
                width: $BatteryWidth;
                height: 80px;
                // background: url('~@/assets/images/chonghuan/battery_green_light.png') no-repeat;
                // background-size: 100% 100%;
                position: relative;
            }
            .battery-100 {
                background: url('~@/assets/images/chonghuan/icon-29.png') no-repeat;
                background-size: 100% 100%;
            }
            .battery-ing {
                background: url('~@/assets/images/chonghuan/icon-30.png') no-repeat;
                background-size: 100% 100%;
                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: url('~@/assets/images/chonghuan/icon-32.png') no-repeat;
                    background-size: 100% 100%;
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 30px;
                    height: 38px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background: url('~@/assets/images/chonghuan/icon-33.png') no-repeat;
                    background-size: 100% 100%;
                }
            }
            .battery-bad {
                background: url('~@/assets/images/chonghuan/icon-31.png') no-repeat;
                background-size: 100% 100%;
            }
            .item-bottom {
                width: $BatteryWidth;
                font-size: $FontSize36;
                text-align: center;
                @include ffamily-HT;
            }
        }
        // .battery-color {
        //     width: $BatteryWidth;
        //     height: 80px;
        //     background: url('~@/assets/images/chonghuan/battery_green_light.png') no-repeat;
        //     background-size: 100% 100%;
        //     position: relative;
        // }
        // .battery-100 {
        //     background: url('~@/assets/images/chonghuan/battery_green.png') no-repeat;
        //     background-size: 100% 100%;
        // }
        // .battery-red {
        //     background: url('~@/assets/images/chonghuan/battery_red.png') no-repeat;
        //     background-size: 100% 100%;
        // }
        // .battery-grey {
        //     background: url('~@/assets/images/chonghuan/battery_grey.png') no-repeat;
        //     background-size: 100% 100%;
        // }
        // .batteryIcon {
        //     width: 20px;
        //     height: 20px;
        //     background: url('~@/assets/images/chonghuan/light-blue.png') no-repeat;
        //     background-size: 100%;
        //     &.red{
        //         background: url('~@/assets/images/chonghuan/light-red.png') no-repeat;
        //         background-size: 100%;
        //     }
        //     &.grey{
        //         background: url('~@/assets/images/chonghuan/light-white.png') no-repeat;
        //         background-size: 100%;
        //     }
        //     &.error{
        //         background: url('~@/assets/images/chonghuan/light-error.png') no-repeat;
        //         background-size: 100%;
        //     }
        // }
    }
}
</style>