<template>
    <div class="station-9 partBox">
        <MyTitle class="flexs" title="充电桩数据" />
        <div class="flex flexs top-info justify-content-between mt-30">
            <div v-for="(item, i) in list"
                :key="'list' + i"
                class="item flex1">
                <!-- <img class="img" :src="item.icon" /> -->
                <div class="number" :style="{'color': item.color }">{{ item.value }}</div>
                <div class="label">{{ item.label }}</div>
            </div>
        </div>
        <div class="box-content" id="poleBox">
            <div class="pole-content"
                id="poleContent"
                :style="{'transform': `translate(0, -${poleContentTop}px)`}">
                <div
                    v-for="(item, i) in poleList"
                    :key="'batteryHolder' + i"
                    class="item mt-20"
                    :class="{'at': atPoleList == i}">
                    <div class="flex item-top">
                        <div class="top-left">
                            <img v-if="item.state == '充电中'" class="img" :src="require('@/assets/images/chonghuan/icon-15.png')" alt="" />
                            <img v-else class="img" :src="require('@/assets/images/chonghuan/icon-16.png')" alt="" />
                            <div class="state flex align-items justify-content mt-20"
                                :class="{'ing': item.state == '充电中',
                                        'free': item.state == '空闲中'}">{{ item.state }}</div>
                        </div>
                        <div class="top-right">
                            <div class="flex align-items">
                                <div class="name">{{ item.name }}</div>
                                <img v-if="item.state == '充电中'" class="img" :src="require('@/assets/images/chonghuan/icon-17.png')" alt="">
                            </div>
                            <div v-for="(itemL, x) in item.list"
                                :key="'itemL' + x"
                                class="i-list mt-10">{{ itemL }}</div>
                        </div>
                    </div>
                    <div v-if="item.state == '充电中'" class="flex item-bottom mt-20">
                        <div v-for="(target, y) in item.target"
                            :key="'target' + y"
                            class="flex1 target">
                            <img class="img" :src="target.icon" alt="">
                            <div class="value"><span>{{ target.value }}</span></div>
                            <div class="label">{{ target.label }}({{ target.unit }})</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MyTitle from '@/views/components/MyTitle'
import { dataInterface } from "@/api/dataInterfaceApi"
export default {
    components: {
        MyTitle,
    },
    data() {
        let { stationId } = this.$route.query || {}
        return {
            stationId,
            list: [
                {
                    label: '充电中',
                    value: '0',
                    color: '#0EFDC6'
                    // icon: require('@/assets/images/chonghuan/icon-13.png'),
                },
                {
                    label: '空闲中',
                    value: '0',
                    color: '#0EFDC6'
                    // icon: require('@/assets/images/chonghuan/icon-14.png'),
                },
                {
                    label: '其他',
                    value: '0',
                    color: '#C4EDD1'
                    // icon: require('@/assets/images/chonghuan/icon-14.png'),
                }
            ],
            poleList: [{}, {}, {}, {}],
            atPoleList: -1,
            poleInterval: null,
            poleContentTop: 0
        }
    },
    mounted() {
        // 获取数据
        this.getData()
    },
	methods: {
        getData() {
        // 站点-右侧-充电桩数据
            this.chargeChargedPileData()
        },
        // 站点-右侧-充电桩数据
        chargeChargedPileData(){
            let _this = this
            let params = {
                'enCode':'TiDB-zhandian-charge-charged-pile-data',
                '@station_id': _this.stationId,
                '@station_type': _this.type == 1 ? '换电站' : '充电站'
            }
            dataInterface.dataFaceApi(params).then((res) => {
                console.log('chonghuan-home:站点-右侧-充电桩数据:TiDB-zhandian-charge-charged-pile-data', res)
                if(!res || !res.length) return
                let num1 = 0
                let num2 = 0
                let arrIng = []
                this.poleList = []
                res.map((item, i) => {
                    if(['充电中'].includes(item.gun_status)) {
                        num1++
                        arrIng.push(i)
                    }
                    if(['空闲中'].includes(item.gun_status)) {
                        num2++
                    }
                    let pList = {
                        name: item.pile_name,
                        index: `#${i}`,
                        state: `${item.gun_status}`,
                        list: [
                            `桩编码：${item.pile_code}`,
                            `额定电压：${parseInt(item.voltage_highest, 10)}V`,
                            `额定功率：${parseInt(item.rated_power, 10)}kW`,
                            `${item.type_name}`
                        ],
                        target: [
                            {
                                value: parseInt(item.real_time_voltage, 10),
                                label: '实时电压',
                                unit: 'V',
                                icon: require('@/assets/images/chonghuan/icon-18.png'),
                            },
                            {
                                value: parseInt(item.real_time_soc, 10),
                                label: '实时SOC',
                                unit: '%',
                                icon: require('@/assets/images/chonghuan/icon-19.png'),
                            },
                            {
                                value: parseInt(item.real_time_electricity, 10),
                                label: '实时电流',
                                unit: 'A',
                                icon: require('@/assets/images/chonghuan/icon-18.png'),
                            },
                        ]
                    }
                    this.poleList.push(pList)
                })
                this.list[0].value = num1
                this.list[1].value = num2
                this.list[2].value = res.length - num1 - num2
                // res.map((item,index) => {
                //     if(this.curBatteryId == item.batteryId){
                //         this.curSingleIndex = index;
                //     }
                // })
                this.openInterval(arrIng)
                console.log('arrIng', arrIng)
            })
            .catch((err) => {
                console.log(err);
            });
        },
        openInterval(arrIng) {
            // 默认选中第一个
            if(!arrIng.length) return
            let arrIngAt = 0
            this.atPoleList = arrIng[arrIngAt]
            console.log('atPoleList', this.atPoleList)
            // 设置高度
            this.setTop(arrIngAt)
            if(arrIng.length > 1) {
                this.poleInterval = setInterval(() => {
                    arrIngAt++
                    if(arrIngAt >= arrIng.length) {
                        arrIngAt = 0
                    }
                    this.atPoleList = arrIng[arrIngAt]
                    console.log('atPoleList', this.atPoleList)
                    // 设置高度
                    this.setTop(arrIngAt)
                }, 6000)
            }
        },
        setTop(arrIngAt) {
            // document.querySelector('#poleContent').style.tr = index * 100
            this.poleContentTop = this.atPoleList * 325 + arrIngAt * 100
            this.$emit('getInfo', this.poleList[this.atPoleList])
        }
    },
    destroyed() {
        clearInterval(this.poleInterval)
    }
}
</script>

<style lang="scss" scoped>
$BatteryWidth: 180px;
.station-9 {
    height: 100%;
    .top-info {
        background: url('~@/assets/images/chonghuan/icon-24.png') bottom no-repeat;
        background-size: 100%;
        padding-left: 70px;
        position: relative;
        &::before {
            position: absolute;
            width: 100px;
            height: 100px;
            top: 0;
            left: 0;
            background: url('~@/assets/images/chonghuan/icon-24-1.png') bottom no-repeat;
            background-size: 100%;
            content: '';
        }
        .item {
            position: relative;
            width: 283px;
            height: 115px;
            text-align: center;
            // padding-left: 40px;
            // .img {
            //     position: absolute;
            //     width: 283px;
            //     height: 78px;
            //     bottom: 0;
            //     left: 0;
            // }
            .number {
                position: relative;
                font-size: $FontSize64;
                @include ffamily-HT;
                height: 60px;
                line-height: 60px;
            }
            .label {
                line-height: 30px;
                position: relative;
                font-size: $FontSize28;
            }
        }
    }
    .box-content {
        // height: calc(100% - );
        // overflow-y: scroll;
        overflow: hidden;
        .pole-content {
            transform: translate(0, 0);
            transition: all .5s;
        }
        .item {
            // background: linear-gradient(0deg, rgba(43, 211, 243, 0.13), rgba(43, 211, 243, 0.13)),
            //     linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
            background: rgba(43, 211, 243, 0.08);
            padding: 30px;
            // height: 300px;
            overflow: hidden;
            &.at {
                background: rgba(43, 211, 243, 0.16);
            }
            .item-top {
                .top-left {
                    position: relative;
                    margin-right: 40px;
                    .img {
                        width: 136px;
                        height: 119px;
                    }
                    .state {
                        width: 140px;
                        height: 55px;
                        color: #fff;
                        font-size: $FontSize28;
                        &.ing {
                            // background: #2C9088;
                            background: url('~@/assets/images/chonghuan/icon-36.png') no-repeat;
                            background-size: 100% 100%;
                        }
                        &.free {
                            // background: #636464;
                            background: url('~@/assets/images/chonghuan/icon-37.png') no-repeat;
                            background-size: 100% 100%;
                        }
                    }
                }
                .top-right {
                    .name {
                        font-size: $FontSize32;
                        font-weight: bold;
                        margin-right: 20px;
                    }
                    .img {
                        width: 75px;
                        height: 36px;
                    }
                    .i-list {
                        position: relative;
                        font-size: $FontSize24;
                        padding-left: 50px;
                        white-space: nowrap;
                        // margin-top: 5px;
                        &::before {
                            position: absolute;
                            content: '';
                            width: 10px;
                            height: 10px;
                            top: 50%;
                            left: 20px;
                            transform: translate(0, -50%) rotate(45deg);
                            background-color: #8E9392;
                            // box-shadow: 0px 0px 8px 0px #464646;
                            // box-shadow: 0px 0px 8px 0px #464646;
                            // box-shadow: 0px 0px 8px 0px #464646;
                            box-shadow: 0px 0px 1px 0px #464646 inset;

                        }
                    }
                }
            }
            .item-bottom {
                .target {
                    position: relative;
                    padding-left: 60px;
                    font-size: $FontSize24;
                    .img {
                        position: absolute;
                        width: 50px;
                        height: 50px;
                        left: 0;
                        top: 50%;
                        transform: translate(0, -50%);
                    }
                    .value {
                        white-space: nowrap;
                        > span {
                            &:first-child {
                                font-size: $FontSize36;
                                color: #FFCA64;
                                font-weight: bold;
                            }
                        }
                    }
                    .label {
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
</style>