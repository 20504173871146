<template>
    <div class="station-3 partBox">
        <MyTitle class="flexs" title="24h充电订单分布" type="2" />
        <div class="box-content flex1 flex">
            <MyEchart
                :visible="orderAndloadRateShow"
                :id="'orderAndloadRate'"
                :options="orderAndloadRateOptions" />
        </div>
    </div>
</template>

<script>
import MyTitle from '@/views/components/MyTitle'
import MyEchart from '@/views/components/charts/echarts'
import * as echarts from "echarts"
import { dataInterface } from "@/api/dataInterfaceApi"
export default {
    components: {
        MyTitle,
        MyEchart
    },
    data() {
        let { stationId, type } = this.$route.query || {}
        return {
            stationId,
            type,
            orderAndloadRateShow: false,
            orderAndloadRateOptions: {}
        }
    },
    mounted() {
        // 获取数据
        this.getData()
    },
	methods: {
        getData() {
            // 电池需求及负荷率
            this.dianchixuqiujihefuhelv()
        },
        
        // 电池需求及负荷率
        dianchixuqiujihefuhelv() {
            let params = {
                'enCode': this.type == 1 ? 'TiDB-zhandian-dianchixuqiujihefuhelv' : 'TiDB-zhandian-charge-order-number',
                '@station_id': this.stationId,
                '@station_type': this.type == 1 ? '换电站' : '充电站'
            }
            dataInterface.dataFaceApi(params).then( res => {
                if(!res || !res.length) return
                this.orderAndloadRateShow = false
                let xData = []
                let yData1 = []
                let yData2 = []
                res.map(item => {
                    xData.push(item.hour_num || item.hour_time)
                    yData1.push(item.frequency || item.order_number)
                    yData2.push(item.station_rate)
                })
                this.orderAndloadRateOptions = {
                    title: {
                        text: '单'
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: xData,
                            axisPointer: {
                                type: 'shadow'
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            // name: '订单数',
                            min: 0,
                            // max: 250,
                            // interval: 50,
                            // axisLabel: {
                            //     formatter: '{value} 单'
                            // }
                        },
                        // {
                        //     type: 'value',
                        //     name: '负荷量',
                        //     min: 0,
                        //     // max: 25,
                        //     // interval: 5,
                        //     axisLabel: {
                        //         formatter: '{value} %'
                        //     }
                        // }
                    ],
                    series: [
                        {
                            // name: '订单数',
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + ' 单';
                                }
                            },
							lineStyle: {
								color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: 'rgba(11, 185, 229, 0)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(14, 202, 250, 1)'
                                }])
							},
                            data: yData1
                        },
                        // {
                        //     // name: '负荷率',
                        //     type: 'line',
                        //     yAxisIndex: 1,
                        //     tooltip: {
                        //         valueFormatter: function (value) {
                        //             return value + ' %';
                        //         }
                        //     },
						// 	lineStyle: {
						// 		color: '#FFD15C'
						// 	},
                        //     data: yData2
                        // }
                    ]
                }
                // // 充电站去掉符合率
                // if(this.type == 2) {
                //     this.orderAndloadRateOptions.series.pop()
                // }
                this.orderAndloadRateShow = true
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.station-3 {
    .box-content {
        .item {
            width: 33%;
            font-size: $FontSize20;
            position: relative;
            .item-c {
                width: 	137px;
                height: 102px;
            }
            .item-l {
                font-size: $FontSize20;
                color: #C6D1DB;
            }
        }
    }
}
</style>