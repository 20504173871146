<template>
    <div class="station-6 partBox">
        <MyTitle class="flexs" title="订单趋势" type="2" />
        <div class="box-content flex1 flex">
            <MyEchart
                :visible="orderTrendsShow"
                id="orderTrends"
                :options="orderTrendsOptions" />
        </div>
    </div>
</template>

<script>
import MyTitle from '@/views/components/MyTitle'
import MyEchart from '@/views/components/charts/echarts'
import * as echarts from "echarts"
import { dataInterface } from "@/api/dataInterfaceApi"
export default {
    components: {
        MyTitle,
        MyEchart
    },
    data() {
        let { stationId, type } = this.$route.query || {}
        return {
            stationId,
            type,
            orderTrendsShow: false,
            orderTrendsOptions: {}
        }
    },
    mounted() {
        // 获取数据
        this.getData()
    },
	methods: {
        getData() {
            // 订单趋势图
            this.dingdanqushitu()
        },
        // 订单趋势图
        dingdanqushitu() {
			let params = {
				'enCode': this.type == 1 ? 'TiDB-zhandian-dingdanqushitu' : 'TiDB-zhandian-chongdiandingdanqushitu',
                '@station_id': this.stationId,
                '@station_type': this.type == 1 ? '换电站' : '充电站'
			}
			dataInterface.dataFaceApi(params).then((res) => {
				if (!res || !res.length) return
				// let data = res && res[0] || {}
                let xData = []
                let yData1 = []
                res.map(item => {
                    xData.push(item.cur_date.replace('-', '/').slice(2, 10))
                    yData1.push(item.power_exchange_order_num)
                })
                this.orderTrendsShow = false
                this.orderTrendsOptions = {
                    title: {
                        text: '单'
                    },
                    xAxis: {
                        type: 'category',
                        data: xData
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            // name: '',
                            type: 'line',
                            smooth: true,
                            showSymbol: false,
                            label: {
                                show: true,
                                position: 'top'
                            },
                            lineStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: 'rgba(139, 207, 208, 1)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(134, 201, 203, 0)'
                                    }
                                ])
                            },
                            areaStyle: {
                                opacity: 0.8,
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: 'rgba(139, 207, 208, 1)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(134, 201, 203, 0)'
                                    }
                                ])
                            },
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + ' 单';
                                }
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            data: yData1
                        },
                    ]
                }
                this.orderTrendsShow = true
			})
			.catch((err) => {
				console.log(err)
			})
		},
    }
}
</script>

<style lang="scss" scoped>
.station-6 {
    .box-content {
        .item {
            width: 33%;
            font-size: $FontSize20;
            position: relative;
            .item-c {
                width: 	137px;
                height: 102px;
            }
            .item-l {
                font-size: $FontSize20;
                color: #C6D1DB;
            }
        }
    }
}
</style>