<template>
    <div class="station-c1">
        <div
            class="img-box"
            :class="{'passenger': stationDesc.indexOf('乘用') >= 0 }">
            <div v-if="stationDesc.indexOf('商用') >= 0">
                <img v-if="stationSate == '换电中'" class="img" src="@/assets/images/chonghuan/s-bg-2.png" />
                <img v-else class="img" src="@/assets/images/chonghuan/s-bg-1.png" />
            </div>
            <div v-if="stationDesc.indexOf('乘用') >= 0">
                <img class="img" src="@/assets/images/chonghuan/s-bg-5.png" />
            </div>
            <div class="point1">
                <div class="inner flex flex-direction">
                    <div class="location flex flexs align-items">
                        {{ stationObj.station_name }} 
                    </div>
                    <div class="flex1">
                        <div class="flex align-items">
                            <div>当天换电时长(h)：</div>
                            <div class="number">
                                {{ stationObj.charge_time || 0 }}
                            </div>
                        </div>
                        <div class="flex align-items">
                            <div>当天电量(kWh)：</div>
                            <div class="number">
                                {{ stationObj.total_charge || 0 }}
                            </div>
                        </div>
                        <div class="flex align-items">
                            <div>当天换电次数(次)：</div>
                            <div class="number">
                                {{ stationObj.charge_num || 0 }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="point2">
                <div class="jiankong"></div>
                <div
                    v-if="videoList.length && stationSate == '换电中'"
                    class="flex video-box flex-wrap">
                    <div
                        v-for="(item, index) in videoList"
                        :key="'videoList' + index"
                        class="video-item">
                        <video
                            :ref="`my-player${index}`"
                            :playUrl="item.url"
                            class="video video-js"
                            muted>
                        </video>
                    </div>
                </div>
            </div>

            <!-- <div class="point2">
                <div class="inner"></div>
                <div class="flex justify-around">
                    <div class="flex1 flex flex-direction align-items">
                        <div class="ecQuantityText">当天换电时长(h)</div>
                        <div class="ecQuantityCounts" style="margin-top: 15px">
                            {{ changeDatteryTime || 0 }}
                        </div>
                    </div>
                    <div class="flex1 flex flex-direction align-items">
                        <div class="ecQuantityText">当天电量(kWh)</div>
                        <div class="ecQuantityCounts" style="margin-top: 15px">
                            {{ sameElectricQuantity || 0 }}
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import { dataInterface } from "@/api/dataInterfaceApi"
export default {
    data() {
        let { stationId } = this.$route.query || {}
        return {
            stationId,
            stationSate: '',
            stationObj: {
                station_name: '',
                charge_time: '',
                total_charge: '',
                charge_num: '',
            },
            videoObj:{},
            videoList: [],
            getDataInterval: null,
            stationDesc: ''
            // stationName: '',
            // battery: {},
            // ChargeNum: '',
            // changeDatteryTime: '',
            // sameElectricQuantity: '',
        }
    },
    mounted() {
        // 获取站点信息
        this.stationInfo()
        // 获取数据
        this.getData()
        if(this.getDataInterval) {
            clearInterval(this.getDataInterval)
            this.getDataInterval = null
        }
        this.getDataInterval = setInterval(() => {
            this.getData()
        }, 15000)
    },
    methods: {
        getData() {
            // 站点-实时状态
            this.shishizhuangtai()
            // 站点-当天换电次数
            this.dayhuandiancishu()
            // 站点-中间-当天电量
            this.dangtianhuandiandianliang()
        },
        // 获取站点信息
        stationInfo() {
			let params = {
				'enCode': "getStationInfo2",
				"@station_id": this.stationId,
			}
			dataInterface.dataFaceApi(params).then((res) => {
                // console.log('res', res)
                if(!res || !res.length) return
                this.stationDesc = res[0]?.stationTypeDesc
            })
        },
		// 站点-实时状态
		shishizhuangtai() {
			let _this = this
			let params = {
				'enCode': "TiDB-站点-shishizhuangtai",
				"@station_id": _this.stationId,
			}
			dataInterface.dataFaceApi(params).then((res) => {
				if (!res || !res.length) return
				console.log('qweqweqweqwe', res)
                this.stationSate = res[0]?.station_state
                if(this.stationSate == '换电中') {
                    // 获取站点监控视频
                    this.getDeviceList()
                }
			})
			.catch((err) => {
				console.log(err);
			})
		},

		// 站点-当天换电次数
		dayhuandiancishu() {
			let _this = this
			let params = {
				'enCode': "TiDB-chongdianjidangtianhuandianshichang",
				"@station_id": _this.stationId,
			}
			dataInterface.dataFaceApi(params).then((res) => {
				if (!res || !res.length) return
				let data = res[0] || {}
                this.stationObj.charge_time = data.ChargeTime
                this.stationObj.charge_num = data.ChargeNum
			})
			.catch((err) => {
				console.log(err);
			})
		},

		// 站点-中间-当天电量
		dangtianhuandiandianliang() {
			let _this = this
            const now = new Date();
            const year = now.getFullYear();
            const month = (now.getMonth() + 1).toString().padStart(2, "0");
            const day = now.getDate().toString().padStart(2, "0");
            const timer = `${year}-${month}-${day}`
			let params = {
				enCode: "TD-dangtianhuandiandianliang-new",
				"@station_id": _this.stationId,
                "@ts": `${timer} 00:00:00`
			}
			dataInterface.dataFaceApi(params).then((res) => {
                console.log('chonghuan-home:站点-中间-空闲中充电中电池数量:TD-dangtianhuandiandianliang-new', res)
				if (!res || !res.length) return
				let data = res[0] || {}
                this.stationObj.total_charge = data.PowerCharge
                this.stationObj.station_name = data.station_name
			})
			.catch((err) => {
				console.log(err)
			})
		},

		// 清除videojs
		clearVideojs() {
			let videoObjTemp = Object.keys(this.videoObj);
			if (videoObjTemp && videoObjTemp.length) {
				videoObjTemp.forEach(v => {
					if (this.videoObj[v]) {
						this.videoObj[v].dispose()
					}
				})
			}
		},
        // 获取站点监控视频
        getDeviceList() {
            let params = {
                stationId: this.stationId
            }
            dataInterface.gclVideoLiveInfoExposition(params).then(res => {
                console.log('getDeviceList', res)
                if(!res) return
                this.videoList = (res.list || []).splice(0, 4)
                this.$nextTick(() => {
                    this.videoList.forEach((v, i) => {
                        if (v.url) {
                            const playerDom = this.$refs[`my-player${i}`][0]
                            Object.assign(this.videoObj, {
                                [`video${i}`]: videojs(
                                    playerDom,
                                    {
                                        autoplay: true, // 设置自动播放
                                        controls: true, // 显示播放的控件
                                        preload: 'metadata',
                                        aspectRatio: '16:9',
                                        fluid: true,
                                        sources: [
                                            // 注意，如果是以option方式设置的src,是不能实现 换台的 (即使监听了nowPlayVideoUrl也没实现)
                                            {
                                                src: playerDom.attributes.playUrl.nodeValue,
                                                type: 'application/x-mpegURL', // 告诉videojs,这是一个hls流
                                            },
                                        ],
                                    },
                                    function () {},
                                ),
                            })
                        }
                    })
                })
            })
        }
    },
    destroyed() {
        clearInterval(this.getDataInterval)
        this.clearVideojs()
    }
}
</script>

<style lang="scss" scoped>
.station-c1 {
    width: 100%;
    height: 100%;
    position: relative;
    .img-box {
        width: 1635px;
        height: 1123px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // 商用车信息
        &.passenger {
            .img {
                width: 2030px;
                height: 653px;
            }
            .point2 {
                top: 15%;
                right: 10%;
                .jiankong {
                    top: -13px;
                    left: -39%;
                }
            }
        }
    }
    .img {
        width: 1635px;
        height: 1123px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .point1,
    .point2,
    .point3 {
        position: absolute;
    }

    .point1 {
        width: 448px;
        height: 231px;
        background: url("~@/assets/images/chonghuan/icon-20.png");
        background-size: 100% 100%;
        bottom: 18%;
        right: 0;

        .inner {
            position: absolute;
            width: 396px;
            height: 188px;
            left: 50px;
            font-size: $FontSize24;
            &::after {
                position: absolute;
                content: '';
                width: 151px;
                height: 208px;
                background: url("~@/assets/images/chonghuan/icon-21.png");
                background-size: 100% 100%;
                left: -190px;
                top: -90px;
                transform: rotate(180deg);
            }
        }
        .location {
            height: 50px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;
            color: #08FFFF;
            font-size: $FontSize32;
            white-space: nowrap
            // padding-left: 50px;
            // .i-icon {
            //     position: absolute;
            //     top: 50%;
            //     left: 6px;
            //     transform: translate(0, -50%);
            //     font-size: $FontSize30;
            // }
        }
        // .circleMove{
        //     position: relative;
        //     width: 90px;
        //     height: 90px;
        //     // border:6px solid $green-text;
        //     border-radius: 50%;
        //     .circleInner {
        //         width: 90px;
        //         height: 90px;
        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
        //         // position: absolute;
        //         background:url('~@/assets/images/chonghuan/circle_icon.png');
        //         background-size: 100% 100%;
        //         // left:50%;
        //         // top:50%;
        //         // transform: translate(-50%,-50%);
        //     }
		// }
        .number {
            font-size: $FontSize40;
            @include ffamily-DS;
            color: #FFD15C;
        }
    }
    .point2 {
        top: 10%;
        right: 0;
        width: 500px;
        .jiankong {
            position: absolute;
            width: 100px;
            height: 100px;
            background: url("~@/assets/images/chonghuan/icon-38.png");
            background-size: 100% 100%;
            top: -103px;
            left: 64%;
            transform: translate(-50%, 0);
        }
        .video-box {
            width: 500px;
            height: 300px;
            background: rgba(#000000, .6);
            padding: 10px 0 0 10px;
            .video-item {
                width: 50%;
                height: 140px;
                padding: 0 10px 10px 0;
                box-sizing: border-box;
                .video {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    // .point2 {
    //     min-width: 340px;
    //     min-height: 146px;
    //     background-image: linear-gradient(180deg,rgba(14, 134, 139, 0.88) 3%,rgba(0, 43, 41, 0.6) 100%);
    //     border: 1px solid #1a7683;
    //     bottom: 28%;
    //     left: 12%;
    //     padding: 12px 15px;
    //     .inner {
    //         width: 94px;
    //         height: 132px;
    //         background: url("~@/assets/images/chonghuan/point.png");
    //         background-size: cover;
    //         position: absolute;
    //         top: -66px;
    //         right: -94px;
    //     }

    //     .ecQuantityText {
    //         background: rgb(106, 173, 172, 0.6);
    //         padding: 4px 9px;
    //         font-size: $FontSize18;
    //     }

    //     .ecQuantityCounts {
    //         font-size: $FontSize40;
    //         @include ffamily-DS;
    //     }
    // }
}
</style>
<style>
	.vjs-loading-spinner{
		opacity: 0;
	}
</style>