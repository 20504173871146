<template>
    <div class="station-2 partBox">
        <MyTitle class="flexs" title="考核指标" />
        <div class="box-content flex1 flex align-items flex-wrap">
            <div
                v-for="(item, i) in list"
                :key="'list' + i"
                class="item flex flex-direction width-50">
                <!-- <img class="item-c" :src="item.icon" alt="" /> -->
                <span class="item-c icon-bg-01">
                    <img :src="item.icon" alt="" />
                </span>
                <div class="item-l">{{ item.label }}</div>
                <dv-digital-flop style="height: 60px; width: 100%" :config="dvDigitalFlopConfig(item)" />
            </div>
        </div>
    </div>
</template>

<script>
import MyTitle from '@/views/components/MyTitle'
import { dataInterface } from "@/api/dataInterfaceApi"
import { fontSize36 } from './comm'
export default {
    components: {
        MyTitle
    },
    data() {
        let { stationId, type } = this.$route.query || {}
        return {
            stationId,
            type,
            list: [
                {
                    label: '月有效换电率(%)',
                    value: '',
                    icon: require('@/assets/images/chonghuan/icon-4.png'),
                    color: '#01D8DD',
                    textAlign: 'left'
                },
                {
                    label: '月客户满意度(%)',
                    value: '',
                    icon: require('@/assets/images/chonghuan/icon-6.png'),
                    color: '#01D8DD',
                    textAlign: 'left'
                },
                {
                    label: '月累计碳减排(cc)',
                    value: '',
                    icon: require('@/assets/images/chonghuan/icon-7.png'),
                    color: '#01D8DD',
                    textAlign: 'left'
                },
                {
                    label: '月换电车辆数(辆)',
                    value: '',
                    icon: require('@/assets/images/chonghuan/icon-5.png'),
                    color: '#01D8DD',
                    toFixed: '0',
                    textAlign: 'left'
                }
            ]
        }
    },
    mounted() {
        // 获取数据
        this.getData()
    },
	methods: {
        // 字体动态
        dvDigitalFlopConfig(item = {}) {
            let counts = parseFloat(item.value || 0)
            let config = {
                number: [parseFloat(counts)],
                // toFixed: !item.toFixed ? 2 : parseInt(item.toFixed || 2, 10),
                textAlign: item.textAlign || 'center',
                // content: `{nt}${item.unit}`,
                style: {
                    fill: item.color || '#fff',
                    fontSize: item.fontSize || fontSize36,
					fontFamily: 'DS-Digital-Bold'
                }
            }
            console.log('==config==', config)
            return config
        },
        
        // 获取数据
        getData(data) {
            // 站点考核指标
            this.kaohezhibiaotanjianpai(data)
        },

        // 站点考核指标
        kaohezhibiaotanjianpai() {
            let params = {
                'enCode': this.type == 1 ? 'TiDB-zhandian-kaohezhibiaotanjianpai-new' : 'TiDB-zhandian-chongdiankaohezhibiaotanjianpai-new',
                '@station_id': this.stationId,
                // '@station_type': this.type == 1 ? '换电站' : '充电站'
            }
            dataInterface.dataFaceApi(params).then( res => {
                if(!res || !res.length) return
                let data = res[0] || {}
                this.list[0].value = data.EffectiveConversionRate
                this.list[1].value = data.CustomerSatisfaction
                this.list[2].value = data.carbon_emissions_cc
                this.list[3].value = data.new_exchange_or_charge_vehicle_num
                if(this.type == 2) {
                    this.list[3].label = '月充电车辆数(辆)'
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.station-2 {
    .box-content {
        padding: 10px 0;
        .item {
            font-size: $FontSize20;
            position: relative;
            padding-left: 110px;
            .item-c {
                width: 	105px;
                height: 80px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                > img {
                    display: block;
                    margin: auto;
                    margin-top: 12px;
                    transform: scale(1.2);
                }
            }
            .item-l {
                font-size: $FontSize20;
                color: #C6D1DB;
            }
        }
    }
}
</style>