<template>
    <div class="chonghuan-station flex justify-content-between">
        <!-- <MyMap /> -->
        <!-- <div class="cover cover-home"></div> -->
        <MyHeader />
        <div class="page-left flex">
            <div class="left-part">
                <Station1 ref="Station1" />
                <Station2 ref="Station2" class="mt-20" />
                <Station3 ref="Station3" class="mt-10" />
            </div>
            <div class="left-part">
                <Station4 ref="Station4" />
                <Station5 ref="Station5" />
                <Station6 ref="Station6" class="mt-30" />
            </div>
        </div>
        <div class="page-center flex1">
            <StationC1 ref="StationC1" v-if="type == 1" />
            <StationC2 ref="StationC2" v-else :info="infoData" />
            <Station7 ref="Station7" class="center-bottom" />
        </div>
        <div class="page-right">
            <Station8 ref="Station8" v-if="type == 1" />
            <Station9 ref="Station9" v-else @getInfo="getInfo" />
        </div>
    </div>
</template>

<script>
import MyHeader from '@/views/components/MyHeader.vue'
import Station1 from './components/station-1.vue'
import Station2 from './components/station-2.vue'
import Station3 from './components/station-3.vue'
import Station4 from './components/station-4.vue'
import Station5 from './components/station-5.vue'
import Station6 from './components/station-6.vue'
import Station7 from './components/station-7.vue'
import Station8 from './components/station-8.vue'
import Station9 from './components/station-9.vue'
import StationC1 from './components/station-c1.vue'
import StationC2 from './components/station-c2.vue'
export default {
    components: {
        MyHeader,
        Station1,
        Station2,
        Station3,
        Station4,
        Station5,
        Station6,
        Station7,
        Station8,
        Station9,
        StationC1,
        StationC2
    },
    data() {
        let { type, interval, stationId } = this.$route.query || {}
        // 581abc7ea6ce47668a3f03eba90523de 石河子北八路站换电站
        // 90d531b781b6479f9ef3f207ed9808d4 鄂钢1号门充电站
        return {
            type: type || 1,
            interval,
            stationId,
            intervalNum: stationId == '581abc7ea6ce47668a3f03eba90523de' ? 15 : 20,
            pageInterval: null,
            infoData: {},
            // getDataInterval: null
        }
    },
    mounted() {
 
        if(this.pageInterval) {
            clearInterval(this.pageInterval)
        } 
        this.pageInterval = null
        if(this.interval) {
            this.pageInterval = setInterval(() => {
                this.intervalNum--
                if(this.intervalNum < 0 ) {
                    this.$router.replace({
                        path: 'chonghuan-station',
                        query: {
                            stationId: this.stationId == '581abc7ea6ce47668a3f03eba90523de' ? '90d531b781b6479f9ef3f207ed9808d4' : '581abc7ea6ce47668a3f03eba90523de',
                            type: this.stationId == '581abc7ea6ce47668a3f03eba90523de' ? 2 : 1,
                            interval: true
                        }
                    })
                }
            }, 1000)
            // document.onmousemove = ((event) => {
            //     console.log(event)
            //     this.intervalNum = this.intervalNumSet
            // })
        }


        // if(this.getDataInterval) {
        //     clearInterval(this.getDataInterval)
        //     this.getDataInterval = null
        // }
        // this.getDataInterval = setInterval(() => {
        //     this.setAllData()
        // }, 5000)
    },
    methods: {
        // setAllData() {
        //     let allRefs = this.$refs
        //     console.log('allRefs', allRefs)
        //     let keys = Object.keys(this.$refs) || []
        //     keys.map(item => {
        //         allRefs[item].getData()
        //     })
        // },
        // 数据
        getInfo(data) {
            console.log('this.infoData', data)
            this.infoData = data
        }
    },
    destroyed() {
        clearInterval(this.pageInterval)
    }
}
</script>

<style lang="scss" scoped>
@import './components/chonghuan.scss';
.chonghuan-station {
    position: relative;
    height: 100%;
    background: url(~@/assets/images/chonghuan/station-bg.png) no-repeat;
    background-size: 100%;
    
    .page-left, .page-right {
        // width: $PageWidth1;
        height: calc(100% - #{$HeaderHeight});
        // position: absolute;
        margin-top: $HeaderHeight;
        position: relative;
        flex-shrink: 0;
    }
    .page-left {
        padding-left: $PageRange;
        .left-part {
            width: 585px;
            &:first-child {
                margin-right: 30px;
            }
        }
    }
    .page-center {
        position: relative;
        .center-bottom {
            position: absolute;
            bottom: 50px;
            left: 50px;
            width: calc(100% - 50px);
        }
    }
    .page-right {
        padding-right: $PageRange;
        width: 655px;
    }
}
</style>