<template>
	<div class="station-7 flex">
		<div
			v-for="(item, i) in list"
			:key="'list' + i"
			class="typeInfo flex flex1">
			<div>
				<img :src="item.icon" alt="" />
			</div>
			<div class="typeInfo-left">
				<div class="text">
					<span>{{ item.label }}</span>
				</div>
				<div class="text-bottom">
					<span>
						<animate-number
							from="0"
							:to="item.value"
							:key="item.value"
							duration="2000" />	
					</span>
					<span class="uuid">{{ item.unit }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
	components: {},
	data() {
        let { stationId, type } = this.$route.query || {}
        return {
            stationId,
			type,
			satation: {},
			list: [
				{
					label: `累计运营天数`,
					value: '',
					icon: require('@/assets/images/vehiclesType/type-1.png'),
					unit: '天'
				},
				{
					label: `累计${type == 1 ? '换' : '充'}电次数`,
					value: '',
					icon: require('@/assets/images/vehiclesType/type-2.png'),
					unit: '次'
				},
				{
					label: `累计${type == 1 ? '换' : '充'}电电量`,
					value: '',
					icon: require('@/assets/images/vehiclesType/type-3.png'),
					unit: 'kWh'
				}
			]
		};
	},
	mounted() {
		this.getData()
	},
	methods: {
		getData() {
			// 获取运营指标
			this.getOperational()
		},
		// 获取运营指标
		getOperational() {
			let that = this
			this.list[0].value = 0
			this.list[1].value = 0
			this.list[2].value = 0
			let params = {
				'enCode': that.type == 1 ? "TiDB-zhandian-zhandianxinxi" : 'TiDB-zhandian-charge-information-down',
				"@station_id": that.stationId,
                '@station_type': that.type == 1 ? '换电站' : '充电站'
			}
			dataInterface.dataFaceApi(params).then((res) => {
				console.log('chonghuan-station:底部指标:TiDB-zhandian-zhandianxinxi', res)
				if (!res || !res.length) return
				let data = res[0] || {}
				if(that.type == 1) {
					this.list[0].value = parseInt(data.safeDays || 0, 10)
					this.list[1].value = parseInt(data.PowerNum || 0, 10)
					this.list[2].value = parseInt(data.elec || 0, 10)
				} else {
					this.list[0].value = parseInt(data.operating_days, 10)
					this.list[1].value = parseInt(data.charge_num, 10)
					this.list[2].value = parseInt(data.total_electricity, 10)
				}

			})
			.catch((err) => {
				console.log(err);
			})
		},
	},
};
</script>

<style lang="scss" scoped>
// @import "../scss/page.scss";
.station-7 {
	width: 100%;
	height: 218px;
	.typeInfo {
		// width: calc(100% / 3);
		height: 218px;

		// background: orange;
		.typeInfo-left {
			flex: 1;

			.text {
				width: 100%;
				height: 50%;
				// background: red;
				position: relative;

				span {
					position: absolute;
					bottom: 0;
					display: block;
					width: 306px;
					height: 47px;
					line-height: 38px;
					background: url("~@/assets/images/vehiclesType/text-bg.png") no-repeat;
					background-size: 100% 100%;
					font-size: $FontSize36;
					padding-left: 43px;
				}
			}

			.text-bottom {
				padding-left: 37px;
				width: 100%;
				height: 50%;
				// background: skyblue;
				span {
					font-size: $FontSize64;
					color: #0efdc6;
					&:first-child {
						@include ffamily-HT;
					}
				}

				.uuid {
					font-size: $FontSize30;
					color: #fff;
				}
			}
		}
	}
}
</style>