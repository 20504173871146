<template>
    <div class="station-c2">
        <div class="img-box">
            <!-- 充电中 -->
            <div v-if="poleObj.state == '充电中'">
                <img class="img" src="@/assets/images/chonghuan/s-bg-3.png" />
                <img class="img-1" src="@/assets/images/chonghuan/s-bg-3-1.png" />
                <img class="img-2" src="@/assets/images/chonghuan/icon-39.png" />
            </div>
            <!-- 未充电 -->
            <img v-else class="img" src="@/assets/images/chonghuan/s-bg-4.png" />
            <div class="point1">
                <div class="inner flex flex-direction">
                    <div class="location flex flexs align-items">
                        {{ stationObj.station_name }} 
                    </div>
                    <div class="flex1">
                        <div class="flex align-items">
                            <div>当天充电时长(h)：</div>
                            <div class="number">
                                {{ stationObj.charge_time || 0 }}
                            </div>
                        </div>
                        <div class="flex align-items">
                            <div>当天充电电量(kWh)：</div>
                            <div class="number">
                                {{ stationObj.total_charge ? parseFloat(stationObj.total_charge).toFixed(2) : 0 }}
                            </div>
                        </div>
                        <div class="flex align-items">
                            <div>当天充电次数(次)：</div>
                            <div class="number">
                                {{ stationObj.charge_num || 0 }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="poleObj.state == '充电中'" class="point2">
                <span class="p-before"></span>
                <div class="inner flex">
                    <div v-for="(target, y) in poleObj.target"
                        :key="'target' + y"
                        class="flex1 target">
                        <!-- <div class="number"><span>{{ target.value }}</span> </div> -->
                        <dv-digital-flop style="height: 60px; width: 110px" :config="dvDigitalFlopConfig(target)" />
                        <div>{{ target.label }}({{ target.unit }})</div>
                    </div>
                </div>
            </div>
            
            <div v-if="poleObj.state == '充电中'" class="point3">
                {{ poleObj.name }}
            </div>
        </div>
    </div>
</template>

<script>
import { dataInterface } from "@/api/dataInterfaceApi"
import { fontSize48 } from './comm'
export default {
    props: {
        info: {
            type: Object,
            default: () => { return {} }
        }
    },
    watch: {
        info(data) {
            this.poleObj = data
        }
    },
    data() {
        let { stationId } = this.$route.query || {}
        return {
            stationId,
            stationObj: {},
            poleObj: this.info,
            getDataInterval: null
            // stationName: '',
            // battery: {},
            // ChargeNum: '',
            // changeDatteryTime: '',
            // sameElectricQuantity: '',
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            // 站点-中间-充电桩信息
            this.chargeinformation()
            if(this.getDataInterval) {
                clearInterval(this.getDataInterval)
                this.getDataInterval = null
            }
            this.getDataInterval = setInterval(() => {
                // 站点-中间-充电桩信息
                this.chargeinformation()
            }, 15000)
        },
        // 字体动态
        dvDigitalFlopConfig(item = {}) {
            let counts = parseFloat(item.value || 0)
            console.log('fontSize48=====', fontSize48)
            let config = {
                number: [parseFloat(counts)],
                // toFixed: 2,
                textAlign: item.textAlign || 'center',
                // content: `{nt}${item.unit}`,
                style: {
                    fill: item.color || '#FFD15C',
                    fontSize: item.fontSize || fontSize48,
					fontFamily: 'DS-Digital-Bold'
                }
            }
            console.log('==config==', config)
            return config
        },
		// 站点-中间-充电桩信息
		chargeinformation() {
			let _this = this
			let params = {
				enCode: "TiDB-zhandian-charge-information",
				"@station_id": _this.stationId,
                '@station_type': _this.type == 1 ? '换电站' : '充电站'
			}
			dataInterface.dataFaceApi(params).then((res) => {
                console.log('chonghuan-home:站点-中间-充电桩信息:TiDB-zhandian-charge-information', res)
				if (!res || !res.length) return
                this.stationObj = res[0] || {}
			})
			.catch((err) => {
				console.log(err)
			})
		},
    }
}
</script>

<style lang="scss" scoped>
.station-c2 {
    width: 100%;
    height: 100%;
    position: relative;
    .img-box {
        width: 1984px;
        height: 1323px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-55%, -43%);
    }
    .img {
        width: 1984px;
        height: 1219px;
    }
    .img-1 {
        position: absolute;
        width: 55px;
        top: 410px;
        right: 514px;
    }
    .img-2 {
        position: absolute;
        width: 380px;
        top: 548px;
        right: 487px;
    }
    .point1,
    .point2,
    .point3 {
        position: absolute;
    }

    .point1 {
        width: 448px;
        height: 231px;
        top: 5%;
        left: 13%;
        background: url("~@/assets/images/chonghuan/icon-20.png");
        background-size: 100% 100%;
        .inner {
            position: absolute;
            width: 396px;
            height: 188px;
            left: 50px;
            font-size: $FontSize24;

        }
        .location {
            height: 50px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;
            color: #08FFFF;
            font-size: $FontSize32;
            white-space: nowrap
        }
        .number {
            font-size: $FontSize40;
            @include ffamily-DS;
            color: #FFD15C;
        }
    }
    .point2 {
        width: 682px;
        height: 106px;
        background: url("~@/assets/images/chonghuan/icon-27.png") 70px bottom no-repeat;
        background-size: 80%;
        top: 8%;
        right: 24%;
        .p-before {
            position: absolute;
            width: 120px;
            height: 120px;
            top: -10px;
            left: -30px;
            background: url('~@/assets/images/chonghuan/icon-27-1.png') bottom no-repeat;
            background-size: 100%;
        }
        // transform: translate(0, -210%); 
        .inner {
            width: 92%;
            padding-left: 100px;
            font-size: $FontSize24;
            &::after {
                position: absolute;
                content: '';
                width: 82px;
                height: 220px;
                background: url("~@/assets/images/chonghuan/icon-35.png");
                background-size: 100% 100%;
                left: 75%;
                top: 95px;
                transform: translate(-50%, 0);
            }
        }
        .number {
            > span {
                font-size: $FontSize40;
                // @include ffamily-DS;
                color: #FFD15C;
                white-space: nowrap;
                font-weight: bold;
            }
        }
    }
    .point3 {
        // background: url("~@/assets/images/chonghuan/icon-34.png");
        // background-size: 100% 100%;
        font-weight: bold;
        height: 76px;
        display: flex;
        align-items: center;
        font-size: $FontSize36;
        top: 24%;
        right: 29%;
        padding: 0 20px;
    }
}
</style>