<template>
    <div class="station-4 partBox">
        <MyTitle class="flexs" title="运营指标" />
        <div class="box-content flex1 flex align-items">
            <div
                v-for="(item, i) in list"
                :key="'list' + i"
                class="item flex1 flex align-items flex-direction">
                <!-- <img v-if="!item.icon" class="item-c" :src="require('@/assets/images/computility/monitor-icon2.png')" alt="">
                <img v-else class="item-c" :src="require('@/assets/images/computility/monitor-icon3.png')" alt=""> -->
                <!-- -->
                <span class="item-c icon-bg-02">
                     <img v-if="type == 1" :src="item.icon" alt="" />
                     <img v-if="type == 2" :src="item.icon1" alt="" />
                </span>
                <!-- <div></div> -->
                <div class="item-l mt-20">{{ item.label }}{{ item.unit }}</div>
                <dv-digital-flop style="height: 100px; width: 100%" :config="dvDigitalFlopConfig(item)" />
            </div>
        </div>
    </div>
</template>

<script>
import MyTitle from '@/views/components/MyTitle'
import { dataInterface } from "@/api/dataInterfaceApi"
import { fontSize48 } from './comm'
export default {
    components: {
        MyTitle
    },
    data() {
        let { stationId, type } = this.$route.query || {}
        return {
            stationId,
            type,
            list: [
                // {
                //     number: '100%',
                //     label: '月换电里程(km)'
                // },
                // {
                //     number: '100%',
                //     label: '月换电次数(次)'
                // },
                // {
                //     number: '100%',
                //     label: '月充电电量(kwh)'
                // },
                {
                    label: '月换电里程(km)',
                    value: '',
                    icon: require('@/assets/images/chonghuan/icon-1.png'),
                    icon1: require('@/assets/images/chonghuan/icon-1-1.png'),
                    color: '#01D8DD',
                    textAlign: 'center'
                },
                {
                    label: '月换电次数(次)',
                    value: '',
                    icon: require('@/assets/images/chonghuan/icon-2.png'),
                    icon1: require('@/assets/images/chonghuan/icon-2-1.png'),
                    color: '#01D8DD',
                    textAlign: 'center'
                },
                {
                    label: '月充电电量(kwh)',
                    value: '',
                    icon: require('@/assets/images/chonghuan/icon-3.png'),
                    icon1: require('@/assets/images/chonghuan/icon-3-1.png'),
                    color: '#01D8DD',
                    textAlign: 'center'
                }
            ]
        }
    },
    mounted() {
        // 获取数据
        this.getData()
        if(this.type == 2) {
            this.list[0].label = '月充电时长(h)'
            this.list[1].label = '月充电次数(次)'
            this.list[2].label = '月充电电量(kWh)'
        }
    },
	methods: {
        // 字体动态
        dvDigitalFlopConfig(item = {}) {
            let counts = parseFloat(item.value || 0)
            console.log('fontSize48=====', fontSize48)
            let config = {
                number: [parseFloat(counts)],
                toFixed: item.toFixed || 0,
                textAlign: item.textAlign || 'center',
                // content: `{nt}${item.unit}`,
                style: {
                    fill: item.color || '#fff',
                    fontSize: item.fontSize || fontSize48,
					fontFamily: 'DS-Digital-Bold'
                }
            }
            console.log('==config==', config)
            return config
        },
        // 获取数据
        getData(data) {
            // 考核指标收入
            this.kaohezhibiaoshouru(data)
        },

        // 考核指标收入
        kaohezhibiaoshouru() {
            let params = {
                // 'enCode': this.type == 1 ? 'TiDB-zhandian-chongdiandianliang' : 'TiDB-zhandian-chongdiankaohezhibiao',
                'enCode': this.type == 1 ? 'TiDB-zhandian-chongdiandianliang-new' : 'TiDB-zhandian-chongdiankaohezhibiao-new',
                '@station_id': this.stationId,
                '@station_type': this.type == 1 ? '换电站' : '充电站'
            }
            dataInterface.dataFaceApi(params).then( res => {
                console.log("chonghuan-station:考核指标收入:TiDB-zhandian-chongdiandianliang", res)
                let data = res && res[0] || {}
                if(this.type == 1) {
                    this.list[0].value = data.battery_mileage || '0' 
                    this.list[1].value = data.power_exchange_num || '0'
                    this.list[2].value = data.totalelect || '0'
                } else {
                    this.list[0].value = data.charge_duration
                    this.list[0].toFixed = 2
                    this.list[1].value = data.charge_num
                    this.list[2].value = data.charge_electric
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.station-4 {
    .box-content {
        // padding-top: 10px;
        .item {
            font-size: $FontSize20;
            position: relative;
            .item-c {
                width: 	120px;
                height: 100px;
                > img {
                    display: block;
                    margin: 7px auto;
                    transform: scale(1.2);
                }
            }
            .item-l {
                font-size: $FontSize20;
                color: #fff;
            }
        }
    }
}
</style>