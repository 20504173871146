<template>
    <div class="station-5 partBox">
        <MyTitle class="flexs" title="日运营业务收入" type="2" />
        <div class="box-content flex1 flex">
            <MyEchart
                :visible="operatingRevenueShow"
                :id="'operatingRevenue'"
                :options="operatingRevenueOptions" />
        </div>
    </div>
</template>

<script>
import MyTitle from '@/views/components/MyTitle'
import MyEchart from '@/views/components/charts/echarts'
import * as echarts from "echarts"
import { dataInterface } from "@/api/dataInterfaceApi"
export default {
    components: {
        MyTitle,
        MyEchart
    },
    data() {
        let { stationId, type } = this.$route.query || {}
        return {
            stationId,
            type,
            operatingRevenueShow: false,
            operatingRevenueOptions: {}
        }
    },
    mounted() {
        // 获取数据
        this.getData()
    },
	methods: {
        getData() {
            // 运营业务收入
            this.yunyingyewushouru()
        },
        // 运营业务收入
        yunyingyewushouru() {
			let params = {
				'enCode': this.type == 1 ? 'TiDB-zhandian-yunyingyewushouru' : 'TiDB-zhandian-chongdianyunyingyewushouru',
                '@station_id': this.stationId
			}
            if(this.type == 2) {
                params['@station_type'] = '充电站'
            }
			dataInterface.dataFaceApi(params).then((res) => {
				if (!res || !res.length) return
				// let data = res && res[0] || {}
                let xData = []
                let yData1 = []
                let yData2 = []
                res.map(item => {
                    xData.push(item.cur_date)
                    yData1.push((parseFloat(item.last_oper_income || 0) / 10000).toFixed(2))
                    yData2.push((parseFloat(item.cur_oper_income || 0) / 10000).toFixed(2))
                })
                this.operatingRevenueShow = false
                this.operatingRevenueOptions = {
                    title: {
                        text: '万元'
                    },
                    color: ['#f2bd4c','#8bcfd0'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: xData
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            name: '上月日营收',
                            type: 'line',
                            smooth: true,
                            showSymbol: false,
                            label: {
                                show: true,
                                position: 'top'
                            },
                            lineStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: 'rgba(235, 190, 77, 1)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(235, 190, 76, 0)'
                                    }
                                ])
                            },
                            areaStyle: {
                                opacity: 0.8,
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: 'rgba(235, 190, 77, 1)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(235, 190, 76, 0)'
                                    }
                                ])
                            },
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + ' 万元';
                                }
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            data: yData1
                        },
                        {
                            name: '本月日营收',
                            type: 'line',
                            smooth: true,
                            showSymbol: false,
                            label: {
                                show: true,
                                position: 'top'
                            },
                            lineStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: 'rgba(139, 207, 208, 1)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(134, 201, 203, 0)'
                                    }
                                ])
                            },
                            areaStyle: {
                                opacity: 0.8,
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: 'rgba(139, 207, 208, 1)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(134, 201, 203, 0)'
                                    }
                                ])
                            },
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + ' 万元';
                                }
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            data: yData2
                        },
                    ]
                }
                this.operatingRevenueShow = true
			})
			.catch((err) => {
				console.log(err)
			})
		},
    }
}
</script>

<style lang="scss" scoped>
.station-5 {
    .box-content {
        .item {
            width: 33%;
            font-size: $FontSize20;
            position: relative;
            .item-c {
                width: 	137px;
                height: 102px;
            }
            .item-l {
                font-size: $FontSize20;
                color: #C6D1DB;
            }
        }
    }
}
</style>