<template>
    <div class="station-1">
        <MyTitle class="flexs" title="站点信息" />
        <div class="box-content flex1 flex flex-wrap pt-30">
            <div v-for="(item, i) in stationInfo"
                :key="'stationInfo' + i">
                <div class="info-item">
                    <div class="item-label" v-html="item.label"></div>
                    <div class="item-value">{{ item.value }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MyTitle from '@/views/components/MyTitle'
import { dataInterface } from "@/api/dataInterfaceApi"
export default {
    components: {
        MyTitle,
    },
    data() {
        let { stationId, type } = this.$route.query || {}
        return {
            type,
            stationId,
            stationInfo: [
                {
                    label: "站点名称：",
                    value: ''
                },
                {
                    label: "建站日期：",
                    value: ''
                },
                {
                    label: "省&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;市：",
                    value: ''
                },  
                {
                    label: "开站日期：",
                    value: ''
                },
                {
                    label: "占地面积：",
                    value: ''
                },
                {
                    label: "服务能力：",
                    value: ''
                },
                {
                    label: "匹配车型：",
                    value: ''
                },
                {
                    label: "站点类型：",
                    value: ''
                },
                {
                    label: "车位数量：",
                    value: ''
                },
                {
                    label: "电池数量：",
                    value: ''
                },
            ],
            infoList: null
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData(data = {}) {
            // 站点信息
            this.getSataionInfo(data)
        },
        // 站点信息
        getSataionInfo() {
            let params = {
                'enCode': this.type == 1 ? 'TiDB-zhandian-zhandianxinxixia' : 'TiDB-zhandian-cdzzdxx',
                '@station_id': this.stationId,
                '@station_type': this.type == 1 ? '换电站' : '充电站'
            }
            dataInterface.dataFaceApi(params).then( res => {
                console.log('chonghuan-home：站点信息', res)
                let data = res && res[0] || {}
                if(this.type == 1) {
                    this.stationInfo[0].value = data.stationName
                    this.stationInfo[1].value = data.constructionTime
                    this.stationInfo[2].value = data.city
                    this.stationInfo[3].value = data.creationDate
                    this.stationInfo[4].value = data.coverAnArea + '平方米'
                    this.stationInfo[5].value = data.stationServiceQuality + '次/天'
                    this.stationInfo[6].value = data.vehicleBrand
                    this.stationInfo[7].value = data.stationType
                    this.stationInfo[8].value = data.parkingPlace
                    this.stationInfo[9].value = data.houseTotalBatteries
                } else {
                    this.stationInfo[0].value = data.stationName
                    this.stationInfo[1].value = data.constructionTime
                    this.stationInfo[2].value = data.province_city
                    this.stationInfo[3].value = data.creationDate
                    this.stationInfo[4].value = data.coverAnArea + '平方米'
                    this.stationInfo[5].label = '营业状态：'
                    this.stationInfo[5].value = data.business_status
                    this.stationInfo[6].label = '站点价格：'
                    this.stationInfo[6].value = data.change_power_price + '元/度'
                    this.stationInfo[7].value = data.station_type
                    this.stationInfo[8].value = data.carport_num
                    this.stationInfo[9].label = '充电桩数量：'
                    this.stationInfo[9].value = data.pile_num
                }

                // this.infoList = JSON.parse(JSON.stringify(this.stationInfo))
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.station-1 {
    .box-content {
        > div {
            width: 50%;
            box-sizing: border-box;
            &:nth-child(odd) {
                padding-right: 5px;
            }
            &:nth-child(even) {
                padding-left: 5px;
            }
            padding-bottom: 10px;
        }
        .info-item {
            background: #01B1A9 linear-gradient(180deg, rgba(14,134,139,0.88) 0%, rgba(0,43,41,0.6) 100%);
            height: 60px;
            font-size: $FontSize20;
            display: flex;
            align-items: center;
            .item-label {
                // width: 115px;
                flex-shrink: 0;
                // display: flex;
                text-align: right;
                white-space: nowrap;
                padding-left: 20px;
            }
            .item-value {
                flex: 1;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
 }
</style>